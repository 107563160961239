import { AsperaService } from 'wonderland-ui-aspera';
import { compose, withHandlers } from 'recompose';
import { getFormData, getTransferType, getUploadData } from 'core/withCreateAsset';
import { isEmpty, take } from 'lodash';
import { META_SOURCE, populateReplacement } from 'lib/assetUploadUtils';
import { ToastController, withProgressDecorator } from 'wonderland-ui-commons';
import { WonderlandDomainAPI } from './apis';
import flatten from 'flat';
import withUpload from './withUpload';

export default () =>
    compose(
        withUpload(),
        withHandlers({
            uploadAsset: ({ signedUrlsEnabled, upload, isFlagEnabled, user }) =>
                withProgressDecorator('upload', { type: 'determinate' })(async function ({
                    id,
                    extraMetadata,
                    notifications,
                    type,
                    files,
                    imf,
                    newAsset,
                    isAttachment,
                    parentId
                }) {
                    try {
                        const transfer_type = getTransferType(AsperaService, signedUrlsEnabled);
                        const httpUrl = isFlagEnabled('UNIFIED_INGEST_HTTP')
                            ? WonderlandDomainAPI.unifiedIngestUrl
                            : WonderlandDomainAPI.assetUploadUrl;
                        const transferData = {
                            assetId: id,
                            fileType: type,
                            notifications,
                            uploadType: 'single',
                            transfer_type,
                            ...(!isEmpty(extraMetadata) && { extraMetadata }),
                            user_name: user.name,
                            newAsset
                        };
                        const unifiedApiData = {
                            rendition: type,
                            transfer_type,
                            ingest_specs: [
                                {
                                    metadata: { source: META_SOURCE.ASSET_ID, asset_id: id },
                                    resource: {}
                                }
                            ],
                            notifications,
                            user_name: user.name,
                            attributes: { new: newAsset }
                        };

                        if (!isEmpty(extraMetadata)) {
                            populateReplacement(extraMetadata, unifiedApiData.ingest_specs[0].metadata, 'replacement');
                        }

                        const file = take(files, 1);
                        const formData = getFormData({ id, type, ...flatten(unifiedApiData) }, file);
                        !isEmpty(extraMetadata) && formData.append('transferData', JSON.stringify({ extraMetadata }));
                        return await upload(
                            getUploadData(
                                this.progressFn,
                                httpUrl,
                                formData,
                                files,
                                transferData,
                                imf,
                                unifiedApiData,
                                isAttachment,
                                parentId
                            )
                        );
                    } catch (e) {
                        ToastController.showError('Failed to upload asset - please make sure you are online.');
                        throw e;
                    }
                })
        })
    );
