export const recipientTypes = {
    internal: 'internalUser',
    external: 'externalUser',
    dl: 'distributionList'
};

export const placeholders = {
    internalUser: 'Type the name or email of an internal user...',
    externalUser: 'Type the email of an external user...',
    distributionList: 'Type the name of a distribution list...'
};

export const displayNames = {
    internalUser: 'User',
    externalUser: 'Guest',
    distributionList: 'Distribution List'
};
