import { AsperaService } from 'wonderland-ui-aspera';
import { compose, withHandlers } from 'recompose';
import { delay, get, isEmpty, mapValues, pickBy, take } from 'lodash';
import { depopulateMeta, ToastController, withProgressDecorator } from 'wonderland-ui-commons';
import { FILETYPE } from 'lib/fileTypes';
import { META_SOURCE, RENDITIONS, TRANSFER_TYPE } from 'lib/assetUploadUtils';
import { removeFieldsWithEmptyStrings } from 'lib/handleEmptyStrings';
import { withFeatureFlaggedFields } from 'core/Cache/withFeatureFlags';
import { WonderlandDomainAPI } from './apis';
import config from 'app/config';
import flatten from 'flat';
import history from 'lib/history';
import withUpload from './withUpload';
import WonderlandDomainController from 'core/WonderlandDomain';

const depopulateObj = { ...config.cvSaasMapping, related_titles: 'related_titles' };
const getFieldValue = (value, field) => (field === 'related_titles' ? value.map(title => title.id) : value);

const getMetadata = (meta = {}, isFieldEnabled) =>
    config.cvSaas.enable
        ? removeFieldsWithEmptyStrings(
              mapValues(
                  pickBy(meta, (value, field) => isFieldEnabled(field)),
                  depopulateMeta(depopulateObj)
              )
          )
        : removeFieldsWithEmptyStrings(
              mapValues(
                  pickBy(meta, (value, field) => isFieldEnabled(field)),
                  getFieldValue
              )
          );
const getSecurity = security => ({ policies: (security.policies || []).map(p => p.id) });

export const getUploadData = (
    progressFn,
    httpUrl,
    formData,
    files,
    transferData,
    imf,
    unifiedApiData,
    isAttachment,
    parentId
) => ({
    // common
    progressFn,
    isAttachment,
    parentId,
    // http
    httpUrl,
    formData,
    // s3
    files,
    // managed
    sources: files.map(f => ({ path: f.name, folder: f.type === 'inode/directory' })),
    transferData,
    imf,

    // unifiedingest
    ...unifiedApiData
});

export const getFormData = (formDataObj, files = []) => {
    const formData = new FormData();
    Object.keys(formDataObj).forEach(field => formData.append(field, formDataObj[field]));
    files.forEach(f => formData.append('uploads', f));

    return formData;
};

export const getTransferType = (AsperaService, signedUrlsEnabled) => {
    return AsperaService.connected
        ? TRANSFER_TYPE.ASPERA_CONNECT
        : signedUrlsEnabled
        ? TRANSFER_TYPE.SIGNED_S3
        : TRANSFER_TYPE.HTTP;
};

const redirectTo = path => history.push(path);

export default opts =>
    compose(
        withUpload({ newAsset: true, isBulkUpload: get(opts, 'isBulkUpload', false) }),
        withFeatureFlaggedFields,
        withHandlers({
            createAsset: ({ isFieldEnabled, isFlagEnabled, signedUrlsEnabled, upload, user }) =>
                withProgressDecorator('upload', { type: 'determinate' })(async function ({
                    destination,
                    imf,
                    meta,
                    notifications,
                    redirectDelay,
                    remoteOrigin,
                    security,
                    template_id,
                    validationData
                }) {
                    const { files } = meta;
                    const newAsset = {
                        meta: getMetadata(meta, isFieldEnabled),
                        security: getSecurity(security),
                        template_id
                    };
                    const hasFiles = files && files.length;
                    if (hasFiles) {
                        newAsset.meta.ingest = { status: 'PENDING' };
                    }

                    try {
                        const asset = await WonderlandDomainAPI.createAsset(newAsset, security);
                        const type =
                            asset.asset_type === 'collection'
                                ? asset.collection_type === 'smart'
                                    ? 'smartCollection'
                                    : 'collection'
                                : 'asset';
                        if (!(imf && remoteOrigin)) {
                            setTimeout(() => redirectTo(`/${type}/${asset.id}`), redirectDelay || 0);
                        }

                        if (hasFiles) {
                            const httpUrl = isFlagEnabled('UNIFIED_INGEST_HTTP')
                                ? WonderlandDomainAPI.unifiedIngestUrl
                                : WonderlandDomainAPI.assetUploadUrl;
                            const file = take(files, 1);
                            const transfer_type = getTransferType(AsperaService, signedUrlsEnabled);
                            const transferData = {
                                assetId: asset.id,
                                fileType: FILETYPE.ORIGINAL,
                                notifications,
                                uploadType: 'single',
                                transfer_type,
                                user_name: user.name
                            };
                            const unifiedApiData = {
                                attributes: { new: true },
                                ingest_specs: [
                                    {
                                        metadata: { source: META_SOURCE.ASSET_ID, asset_id: asset.id },
                                        resource: {}
                                    }
                                ],
                                notifications,
                                rendition: RENDITIONS.ORIGINAL,
                                transfer_type,
                                user_name: user.name
                            };
                            const formData = getFormData(
                                { id: asset.id, type: FILETYPE.ORIGINAL, ...flatten(unifiedApiData) },
                                file
                            );
                            const ingestData = { assetId: asset.id, destinationId: destination.id, resource: files[0] };
                            const imfArgs = { ingestData, validationData };

                            if (remoteOrigin) {
                                imf
                                    ? await WonderlandDomainAPI.validateIngestOrPropagateIMF(imfArgs)
                                    : await WonderlandDomainController.resourceIngest({
                                          resource: files[0],
                                          type: FILETYPE.ORIGINAL,
                                          destination,
                                          notifications,
                                          unifiedIngest: isFlagEnabled('UNIFIED_INGEST_REMOTE'),
                                          asset
                                      });
                            } else {
                                await upload(
                                    getUploadData(
                                        this.progressFn,
                                        httpUrl,
                                        formData,
                                        files,
                                        transferData,
                                        imf,
                                        unifiedApiData
                                    )
                                );
                            }
                        }

                        return asset;
                    } catch (e) {
                        /* istanbul ignore next */
                        ToastController.showError('Failed to upload asset - please make sure you are online.');
                        throw e;
                    }
                }),
            bulkUpload: ({ upload, isFieldEnabled, isFlagEnabled, user }) =>
                withProgressDecorator('bulk_upload', { type: 'determinate' })(async function ({
                    asset_type,
                    meta,
                    security: sec,
                    files,
                    template_id,
                    remoteOrigin,
                    destination
                }) {
                    ToastController.show(
                        `Your ${asset_type} files are uploading now. You'll be notified when their processing is complete.`
                    );
                    redirectTo('/');

                    const httpUrl = isFlagEnabled('UNIFIED_INGEST_BULK')
                        ? WonderlandDomainAPI.unifiedIngestUrl
                        : WonderlandDomainAPI.bulkUploadUrl;
                    const metadata = getMetadata(meta, isFieldEnabled);
                    const security = getSecurity(sec);
                    const uploadData = {
                        asset_type,
                        ...(!isEmpty(metadata) && { meta: JSON.stringify(metadata) }),
                        ...(!isEmpty(security.policies) && { security: JSON.stringify(security) }),
                        ...(template_id && { template_id })
                    };
                    const formData = getFormData(uploadData, files);

                    const transferData = {
                        asset_type,
                        meta: !isEmpty(metadata) ? metadata : {},
                        security: !isEmpty(security.policies) ? security : {},
                        uploadType: 'multi',
                        template_id,
                        user_name: user.name
                    };

                    return (
                        remoteOrigin
                            ? WonderlandDomainController.bulkResourceIngest({
                                  resources: JSON.stringify(files),
                                  files,
                                  uploadData,
                                  destination,
                                  unifiedIngest: isFlagEnabled('UNIFIED_INGEST_BULK'),
                                  user_name: user.name,
                                  meta,
                                  security,
                                  template_id
                              })
                            : upload(getUploadData(this.progressFn, httpUrl, formData, files, transferData))
                    ).then(delay(ToastController.hide, 1500), ToastController.showError);
                })
        })
    );
