import { get } from 'lodash';
import { recipientTypes } from 'core/Destinations/DeliveryFormConstants';

const { internal, dl } = recipientTypes;

function _filterSystemUsers(user) {
    const userPatternsToExclude = [/asset-loader/];

    return userPatternsToExclude.reduce((shouldIncludeUser, userPattern) => {
        return shouldIncludeUser && !get(user, 'name', '').match(userPattern);
    }, true);
}

function _formatDistributionList(distributionList) {
    const { name } = distributionList;
    distributionList.type = dl;

    return {
        text: name,
        value: distributionList
    };
}

function _formatUser(user) {
    const { email, first_name, last_name } = user;
    user.type = internal;

    return {
        text: `${first_name} ${last_name} <${email}>`,
        value: user
    };
}

export const formatDistributionLists = distributionLists => distributionLists.map(_formatDistributionList);

export const formatNonSystemUsers = users => users.filter(_filterSystemUsers).map(_formatUser);

export const formatUsers = users => users.map(_formatUser);
