import { compose, withHandlers } from 'recompose';
import { get, head, isArray, isString } from 'lodash';
import { ToastController } from 'wonderland-ui-commons';
import { validation } from 'lib/form';
import RecipientSearchComponent from './RecipientSearchComponent';
import RecipientSearchController from './RecipientSearchController';

const { isEmail } = validation;
const { showError } = ToastController;
const { search } = RecipientSearchController;
const invalidMessage = value => `"${value}" is an invalid email address`;
const searchParams = { includeInactive: false, limit: 20, skip: 0 };

export default compose(
    withHandlers({
        handleChange:
            ({ addNotificationRecipient }) =>
            item => {
                const selectedItem = isArray(item) ? head(item) : item;
                const type = get(selectedItem, 'value.type', 'externalUser');
                const value = get(selectedItem, 'value');

                type === 'externalUser' && !isEmail(value)
                    ? showError(invalidMessage(value))
                    : addNotificationRecipient(selectedItem, type);
            },
        handleOptions: () => filter => search({ filter, ...searchParams }),
        handleOptionLabel:
            () =>
            ({ value, text }) =>
                isString(value) && !isEmail(value) ? invalidMessage(value) : text || value
    })
)(RecipientSearchComponent);
